import useFetch, { CachePolicies } from "use-http";
import { firebaseEndPoint } from "../config";
export const updateLastUSed = async (accessToken) => {
  try {
    const headers = new Headers();
    headers.append("authorization", `Bearer ${accessToken}`);
    headers.append("Content-Type", "application/json");
    const body = JSON.stringify({});

    const update = await fetch(`${firebaseEndPoint}/update_last_used`, {
      method: "POST",
      headers,
      body,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        return { data: res?.message };
      })
      .catch((error) => {
        console.log("error", error);
        throw "GET_TOKEN_ERROR";
      });

    return { message: update?.data?.message };
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};
