export default {
  button: {
    primary: {
      base: "text-white bg-fuchsia-800 border border-transparent",
      active:
        "active:bg-fuchsia-800 hover:bg-fuchsia-700 focus:ring focus:ring-fuchsia-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
  },
  // Input
  input: {
    checkbox:
      "text-fuchsia-600  form-checkbox focus:border-fuchsia-400 focus:outline-none focus:ring focus:ring-fuchsia-300 focus:ring-offset-0 rounded dark:focus:ring-gray-300",
  },
  // Backdrop
  backdrop: {
    base: "absolute left-0 top-0 w-full z-40 bg-black bg-opacity-75 flex items-end sm:items-center sm:justify-center overflow-auto min-h-screen",
  },
};
