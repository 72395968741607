import React, { createContext, useState, useEffect } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import { FiPlay, FiShuffle, FiVolume1, FiPause, FiHeart } from "react-icons/fi";
import {
  BiDotsVerticalRounded,
  BiVolumeMute,
  BiVolumeFull,
} from "react-icons/bi";
import { RiPlayList2Fill, RiHeartFill } from "react-icons/ri";
import {
  MdOutlineSkipNext,
  MdOutlineSkipPrevious,
  MdOutlineReorder,
  MdRepeatOne,
  MdRepeat,
  MdOutlineShuffle,
  MdClose,
} from "react-icons/md";
import { ImLoop, ImShuffle, ImCross } from "react-icons/im";

import { FaHeart } from "react-icons/fa";
import "../assets/css/music.css";
export const MusicContext = createContext({});
export const MusicProvider = ({ children }) => {
  const [music, setMusic] = useState([]);
  const [currentMusic, setCurrentMusic] = useState({});
  const setMusicData = (data) => {
    const m = music;
    setMusic([data, ...music]);
  };
  const lyrics = async (fileName) => {
    const get = await fetch(fileName)
      .then((response) => response.text())
      .then((data) => {
        return data;
      });
    return get;
  };
  const extendsContent = (
    <div className="flex">
      <span className="btn btn-gradient rounded-full px-1 w-16 h-10 text-md text-white text-center  font-semibold flex justify-around items-center ">
        <RiHeartFill className="text-fuchsia-300" color="#f0abfc" />{" "}
        {currentMusic?.likes > 1000
          ? `${currentMusic?.likes / 1000}K`
          : currentMusic?.likes || 0}
      </span>
      <button
        className="right-0 absolute align-baseline "
        onClick={(e) => {
          setCurrentMusic({});
          setMusic([]);
        }}
      >
        <MdClose color="#000" className="w-6 h-10 text-black" />
      </button>
    </div>
  );
  return (
    <MusicContext.Provider
      value={{ music, setMusicData, currentMusic, lyrics }}
    >
      {children}
      {music?.length > 0 ? (
        <ReactJkMusicPlayer
          clearPriorAudioLists={true}
          onAudioPlay={(audioInfo) => {
            setCurrentMusic(audioInfo);
          }}
          onAudioPause={(audioInfo) => {
            setCurrentMusic("");
          }}
          theme="light"
          mode="full"
          spaceBar={true}
          style={{ color: "#000", backgroundColor: "#000" }}
          icon={{
            play: (
              <button className="btn btn-gradient rounded-full w-10 h-10 text-lg text-white flex items-center justify-center">
                <FiPlay color="#fff" />
              </button>
            ),
            pause: (
              <button className="btn btn-gradient rounded-full w-10 h-10 text-lg text-white flex items-center justify-center">
                <FiPause color="#fff" />
              </button>
            ),
            lyric: (
              <button className="btn btn-gradient text-white py-3 px-6 rounded-full">
                Lyrics
              </button>
            ),
            volume: <BiVolumeFull color="#000" />,
            mute: <BiVolumeMute color="#000" />,
            prev: (
              <MdOutlineSkipPrevious
                className="w-8 h-8 cursor-pointer"
                color="#000"
              />
            ),
            next: (
              <MdOutlineSkipNext
                className="w-8 h-8 cursor-pointer"
                color="#000"
              />
            ),
            orderLoop: <MdRepeat className="player-button" color="#000" />,
            shuffle: (
              <MdOutlineShuffle className="player-button" color="#000" />
            ),
            order: <MdOutlineReorder className="player-button" color="#000" />,
            loop: <MdRepeatOne className="player-button" color="#000" />,
            playLists: (
              <RiPlayList2Fill className="player-button" color="#000" />
            ),
          }}
          onBeforeDestroy={() => {
            setCurrentMusic("");
          }}
          autoPlay={true}
          showLyric={true}
          showDownload={false}
          toggleMode={false}
          showThemeSwitch={false}
          showReload={false}
          responsive={true}
          preload="metadata"
          audioLists={music}
          extendsContent={extendsContent}
        />
      ) : null}
    </MusicContext.Provider>
  );
};
